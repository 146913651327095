import { History } from 'history'

import { messages } from '../locale'

export const getRoute = (name?: string) => {
    if (name?.includes('LuckyQuizz')) {
        return 'QUIZZ'
    } else if (name?.includes('LuckyGoal')) {
        return 'TIR_AU_BUT'
    } else if (name?.includes('LuckyWheel')) {
        return 'ROUE_FORTUNE'
    }
}

export const getFullRoute = (name?: string) => {
    if (name?.includes('LuckyQuizz')) {
        return '/home/quizz'
    } else if (name?.includes('LuckyGoal')) {
        return '/home/tir-au-but'
    } else if (name?.includes('LuckyWheel')) {
        return '/home/roue-de-la-fortune'
    }
}

export const goHome = () => {
    window.location.replace('/home')
}

/**
 *  Redirect to a specific page
 */
export const handleRedirection = (
    currentPage: string,
    history: History,
    language: string,
    tab = 'home',
    append: string = '',
    prepend: string = ''
) => {
    // Handle tabs
    if (currentPage === 'HOME_PAGE') {
        // window.location.replace('/home')
        history.replace('/home')
    } else if (currentPage === 'PROFILE_PAGE') {
        // window.location.replace('/profile')
        history.replace('/profile')
    } else if (currentPage === 'EARNINGS_PAGE') {
        // window.location.replace('/earnings')
        history.replace('/earnings')
    } else if (currentPage === 'RESULT_PAGE') {
        // window.location.href = `/${tab}${append}`;
        history.replace(`/${tab}${append}`)
        
    }
    // Else
    else {
        if (append && prepend) {
            history.push(`/${tab}/${prepend}/${messages[language][currentPage].META.URL}/${append}/`)
        } else if (append && !prepend) {
            history.push(`/${tab}/${messages[language][currentPage].META.URL}/${append}/`)
        } else {
            history.push(`/${tab}/${messages[language][currentPage].META.URL}/`)
        }
    }
}
