import { createHttpRequest } from '../utils/services'
import { GAME_SETTINGS_ENDPOINT } from '../config/endpoints'

export const gameServices = {
    /**
     * getSettings get game settings
     *
     * @param {string} place
     * @return {*}
     */
    async getSettings(place: string) {
        const endpoint = GAME_SETTINGS_ENDPOINT.replace(':slug', place)
        const res = await createHttpRequest(endpoint, { method: 'GET' })
        if (!res.error.length) {
            return res.data
        } else {
            return res.error
        }
    }
}
