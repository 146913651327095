import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

declare const window: any
export let history: any = {}

if (typeof window !== 'undefined') {
	history = createBrowserHistory()
}

export default routerMiddleware(history)
