import { createAsyncThunk, createSlice, AsyncThunk } from '@reduxjs/toolkit'

import { RequestStatus } from '../../../config/constants'
import { REGISTER_ENDPOINT } from '../../../config/endpoints'
import { createHttpRequest } from '../../../utils/services'
import { RegisterState } from './register.type'
import { Profile } from '../profile/profile.type'

export const register: AsyncThunk<void, Profile, {}> = createAsyncThunk('register/register', async (profile: Profile) => {
	const response = await createHttpRequest(
		REGISTER_ENDPOINT,
		{
			method: 'POST',
			params: profile
		})

	return response.data
})

export const initialState: RegisterState = {
	status: RequestStatus.IDLE,
	error: null
}

const registerSlice = createSlice({
	name: 'register',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(register.pending, (state) => {
				state.status = RequestStatus.PENDING
			})
			.addCase(register.fulfilled, (state) => {
				state.status = RequestStatus.FULFILLED
			})
			.addCase(register.rejected, (state, action) => {
				state.status = RequestStatus.REJECTED
				state.error = action.error.message
			})
			.addDefaultCase(() => {})
	}
})

const { reducer } = registerSlice

export default reducer
