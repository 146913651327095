import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { createAsyncThunk, createSlice, AsyncThunk } from '@reduxjs/toolkit'

import { RequestStatus } from '../../../config/constants'
import { CREATE_TOKEN_ENDPOINT, SOCIAL_LOGIN_ENDPOINT } from '../../../config/endpoints'
import { createHttpRequest } from '../../../utils/services'
import { LoginState } from './login.type'
import { fetchProfile } from '../profile/profile.slice'
import { RootState } from '../..'

export const login: AsyncThunk<{ access: string }, { email: string }, {}> = createAsyncThunk('login/login', async ({ email, password }: { email: string, password: string }) => {
	const response = await createHttpRequest(
		CREATE_TOKEN_ENDPOINT,
		{
			method: 'POST',
			params: { email }
		})

	localStorage.setItem('userToken', JSON.stringify(response.data))

	return response.data
})

export const loginWithCode: AsyncThunk<{ access: string }, { accessCode: string }, {}> = createAsyncThunk('login/login', async ({ accessCode }: { accessCode: string }) => {
	const response = await createHttpRequest(
		'auth/login/',
		{
			method: 'POST',
			params: { accessCode }
		})

	localStorage.setItem('userToken', JSON.stringify(response.data))

	return response.data
})

export const socialLogin: AsyncThunk<{ access: string }, { platform: string, accessToken: string }, {}> = createAsyncThunk('login/socialLogin', async ({ platform, accessToken }: { platform: string, accessToken: string }) => {
	const response = await createHttpRequest(
		SOCIAL_LOGIN_ENDPOINT,
		{
			method: 'POST',
			params: { provider: platform, accessToken }
		})

	localStorage.setItem('userToken', JSON.stringify(response.data))

	return response.data
})

export const logout = (): ThunkAction<void, RootState, unknown, AnyAction> =>
	async dispatch => {
		localStorage.removeItem('userToken')
		dispatch(logoutAction())
	}

export const tokenVerification = (): ThunkAction<void, RootState, unknown, AnyAction> =>
	async dispatch => {
		const userToken = localStorage.getItem('userToken')

		if (userToken) {
			const token = JSON.parse(userToken)

			if (!token.access) {
				return
			}

			dispatch(fetchProfile(token.access))
		}
	}

export const initialState: LoginState = {
	userToken: {
		access: null
	},
	status: RequestStatus.IDLE,
	error: null
}

const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		logout: () => initialState
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.pending, (state) => {
				state.status = RequestStatus.PENDING
			})
			.addCase(login.fulfilled, (state) => {
				state.status = RequestStatus.FULFILLED
			})
			.addCase(login.rejected, (state, action) => {
				state.status = RequestStatus.REJECTED
				state.error = action.error.message
			})
			.addCase(socialLogin.pending, (state) => {
				state.status = RequestStatus.PENDING
			})
			.addCase(socialLogin.fulfilled, (state) => {
				state.status = RequestStatus.FULFILLED
			})
			.addCase(socialLogin.rejected, (state, action) => {
				state.status = RequestStatus.REJECTED
				state.error = action.error.message
			})
			.addDefaultCase(() => {})
	}
})

const { actions, reducer } = loginSlice

export const { logout: logoutAction } = actions

export default reducer
