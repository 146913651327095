import { ClearAll } from '@mui/icons-material'
import { get, set, remove } from '../utils/IonicStorage'
/**
 IonicStorage is a wrapper around LocalStorage, SessionStorage and IndexedDB that makes it easy to store key/value pairs and JSON objects.
 https://ionicframework.com/docs/building/storage
 */
const userStorage = {
	setToken (token:string) {
		set('token', token)
	},
	async getToken () {
		return await get('token')
	},
	setUser (user:any) {
		set('user', user)
	},
	async getUser () {
		return await get('user')
	},
	async setIsNewUser (value: boolean, email: string) {
		return await set('isNewUser', { newUser: value, newUserEmail: email })
	},
	async getIsNewUser () {
		return await get('isNewUser')
	},
	setLang (lang:any) {
		set('lang', lang)
	},
	async getLang () {
		return await get('lang')
	},
	clearAll () {
		remove('user')
		remove('token')
		remove('lang')
		remove('isNewUser')
	}
}

export default userStorage
