import { getPlatforms } from '@ionic/react'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'

// gtag function for Google Analytics use in web and hybrid apps
export const gtagEvent = (action: string, params: any) => {
	const platform = getPlatforms()
	if (platform.includes('hybrid')) {
		FirebaseAnalytics.logEvent({ name: action, params })
	}
	else {
		// @ts-ignore
		window.tarteaucitron.user.gtagMore = function () {
			// @ts-ignore
			function gtag (type: string, action: string, params: any) { window.dataLayer.push(arguments) }
			gtag('event', action, params)
			gtag('event', 'screen_view', {
				screenName: params.page_location + ':pwa' + platform.includes('ios') ? '-ios' : '-android'
			  })
		};
		// @ts-ignore
		(window.tarteaucitron.job = window.tarteaucitron.job || []).push('gtag')
	}
}
