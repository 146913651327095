import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { createAsyncThunk, createSlice, AsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { RequestStatus } from '../../../config/constants'
import { FETCH_LANGUAGES_ENDPOINT } from '../../../config/endpoints'
import { createHttpRequest } from '../../../utils/services'
import { I18nState } from './i18n.type'
import { RootState } from '../..'

export const fetchLanguages: AsyncThunk<Array<string>, void, {}> = createAsyncThunk('i18n/fetchLanguages', async () => {
	const response = await createHttpRequest(FETCH_LANGUAGES_ENDPOINT, {
		method: 'GET'
	})

	return response.data
})

export const updateLanguage = (language: string): ThunkAction<Promise<string>, RootState, unknown, AnyAction> =>
	async dispatch => {
		console.log('updateLanguage :', language)
		return new Promise((resolve, reject) => {
			try {
				dispatch(i18nSlice.actions.updateLanguage(language))
				resolve(language)
			} catch (error) {
				reject(error)
			}
		})
	}

export const initialState: I18nState = {
	language: 'fr',
	languages: [],
	status: RequestStatus.IDLE,
	error: null
}

const i18nSlice = createSlice({
	name: 'i18n',
	initialState,
	reducers: {
		updateLanguage: (state, action: PayloadAction<string>) => {
			state.language = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLanguages.pending, (state) => {
				state.status = RequestStatus.PENDING
			})
			.addCase(fetchLanguages.fulfilled, (state, action) => {
				state.status = RequestStatus.FULFILLED
				state.languages = action.payload
			})
			.addCase(fetchLanguages.rejected, (state, action) => {
				state.status = RequestStatus.REJECTED
				state.error = action.error.message
			})
			.addDefaultCase(() => {})
	}
})

const { actions, reducer } = i18nSlice

export const { updateLanguage: updateLanguageAction } = actions

export default reducer
