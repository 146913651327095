import { createHttpRequest } from '../utils/services'

export const appService = {
	/**
	 * getSettings get settings for the app
	 *
	 * @param {string} place
	 * @param {string} pageId
	 * @return {*}
	 */
	async getSettings (place:string, pageId:string) {
		const res = await createHttpRequest(`places/${place}?pageId=${pageId}`, { method: 'GET' })
		if (!res.error) {
			return res.data
		} else {
			return res.error
		}
	},
	/**
	 * getGLobalSettings get global settings (cgu..)
	 * @returns
	 * @memberof appService
	 * @returns {*}
	 * 	*/
	async getGLobalSettings () {
		const res = await createHttpRequest('settings', { method: 'GET' })
		if (!res.error) {
			return res.data
		} else {
			return res.error
		}
	}

}
