import { createAsyncThunk, createSlice, AsyncThunk } from '@reduxjs/toolkit'

import { RequestStatus } from '../../../config/constants'
import { FETCH_PROFILE_ENDPOINT } from '../../../config/endpoints'
import { createHttpRequest } from '../../../utils/services'
import { Event, EventState } from './event.type'

export const fetchEvent: AsyncThunk<Event, string, {}> = createAsyncThunk('event', async (accessToken: string) => {
	const response = await createHttpRequest(
		FETCH_PROFILE_ENDPOINT,
		{
			method: 'GET'
		},
		accessToken
	)

	return response.data
})

export const initialState: EventState = {
	event: {
		name: '',
		settings: {
			gradient: {
				from: '#ff0000',
				to: 'transparent'
			},
			event: '../../../assets/fakeData/exampleEvent.png',
			background: '../../../assets/fakeData/stade-rennais-roazhon-park.jpeg',
			menuColor: {
				background: 'rgba(196,10,10,0.6)',
				button: '#ff0000'
			},
			backgroundColor: '#000000'
		}
	},
	status: RequestStatus.IDLE,
	error: null
}

const eventSlice = createSlice({
	name: 'event',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchEvent.pending, (state) => {
				state.status = RequestStatus.PENDING
			})
			.addCase(fetchEvent.fulfilled, (state, action) => {
				state.status = RequestStatus.FULFILLED
				state.event = action.payload
			})
			.addCase(fetchEvent.rejected, (state, action) => {
				state.status = RequestStatus.REJECTED
				state.error = action.error.message
			})
			.addDefaultCase(() => {})
	}
})

const { reducer } = eventSlice

export default reducer
