import { get, set, remove } from '../utils/IonicStorage'
/**
 IonicStorage is a wrapper around LocalStorage, SessionStorage and IndexedDB that makes it easy to store key/value pairs and JSON objects.
 https://ionicframework.com/docs/building/storage
 */
const appConfig = {
    /* Ads Config */
    setAdsConfig(adsConfig: { siteId: string; pageId: string }) {
        set('adsConfig', adsConfig)
    },
    async getAdsConfig() {
        return await get('adsConfig')
    },
    removeAdsConfig() {
        remove('adsConfig')
    },
    /* Event Config */
    setEvent(event: any) {
        set('event', event)
    },
    async getEvent() {
        const event = await get('event')
        return event
    },
    removeEvent() {
        remove('event')
    },
    /* Site Config */
    setSite(site: any) {
        set('site', site)
    },
    async getSite() {
        return await get('site')
    },
    async getSlug(){
        return await get('slug')
    },
    removeSite() {
        remove('site')
    },
    /* Games Config */
    setGames(games: any) {
        set('games', games)
    },
    async getGames() {
        return await get('games')
    },
    removeGames() {
        remove('games')
    },
    setGameFromPage(page: string) {
        //set('prevGamePage', page)
        set('prevGamePage', 'discover')
    },
    async getGameFromPage() {
        return await get('prevGamePage')
    },
    /* Global Config */
    setGlobal(settings: any) {
        set('global', settings)
    },
    async getGlobal() {
        return await get('global')
    },
    /* Home Ads Config */
    setHomeAds(ads: any) {
        set('homeAds', ads)
    },
    async getHomeAds() {
        return await get('homeAds')
    },
    /* Home Prem Ads Config */
    setHomePremAds(ads: any) {
        set('homePremAds', ads)
    },
    async getHomePremAds() {
        return await get('homePremAds')
    },
    /* Profit Ads Config */
    // prenium
    setPreniumProfitAds(ads: any) {
        set('preniumProfitAds', ads)
    },
    async getPreniumProfitAds() {
        return await get('preniumProfitAds')
    },
    //premAnim
    setHomePremAnim(ads: any) {
        set('homeAnimPrem', ads)
    },
    async getHomePremAnim() {
        return await get('homeAnimPrem')
    },
    //Anim
    setAnim(ads: any) {
        set('homeAnim', ads)
    },
    async getAnim() {
        return await get('homeAnim')
    },
    // here
    setHereProfitAds(ads: any) {
        set('hereProfitAds', ads)
    },
    async getHereProfitAds() {
        return await get('hereProfitAds')
    },
    // elsewhere
    setElsewhereProfitAds(ads: any) {
        set('elsewhereProfitAds', ads)
    },
    async getElsewhereProfitAds() {
        return await get('elsewhereProfitAds')
    },
    removeAllConfig() {
        remove('site')
        remove('qr')
        remove('event')
        remove('adsConfig')
        remove('global')
        remove('homeAds')
        remove('preniumProfitAds')
        remove('homePremAds')
        remove('homeAnimPerm')
        remove('homeAnim')
        remove('hereProfitAds')
        remove('elsewhereProfitAds')
        remove('slug')
        remove('prevGamePage')

    },
    removeAllAds() {
        remove('homeAds')
        remove('homePremAds')
        remove('homeAnimPerm')
        remove('homeAnim')
        remove('preniumProfitAds')
        remove('hereProfitAds')
        remove('elsewhereProfitAds')
    }
}

export default appConfig
