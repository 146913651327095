import { AnyAction, configureStore } from '@reduxjs/toolkit'
import { Dispatch } from 'react'
import thunk, { ThunkAction } from 'redux-thunk'

import reducer from './features'
import routerMiddleware, { history } from './middlewares/router'

const configureStoreWithMiddlewares = configureStore({
	reducer: reducer(history),
	middleware: [thunk, routerMiddleware]

})

export type AppStore = typeof configureStoreWithMiddlewares
export type RootState = ReturnType<typeof configureStoreWithMiddlewares.getState>
export type AppDispatch = typeof configureStoreWithMiddlewares.dispatch & Dispatch<ThunkAction<void, RootState, unknown, AnyAction>>

export default configureStoreWithMiddlewares
