import { createAsyncThunk, createSlice, AsyncThunk } from '@reduxjs/toolkit'

import { RequestStatus } from '../../../config/constants'
import { RESET_PASSWORD_ENDPOINT, RESET_PASSWORD_CONFIRM_ENDPOINT } from '../../../config/endpoints'
import { createHttpRequest } from '../../../utils/services'
import { ResetPasswordState } from './resetPassword.type'

export const resetPassword: AsyncThunk<void, string, {}> = createAsyncThunk('resetPassword/resetPassword', async (email: string) => {
	const response = await createHttpRequest(
		RESET_PASSWORD_ENDPOINT,
		{
			method: 'POST',
			params: { email }
		})

	return response.data
})

export const resetPasswordConfirm: AsyncThunk<void, { token: string, uid: string, newPassword1: string, newPassword2: string }, {}> = createAsyncThunk('resetPassword/resetPasswordConfirm', async ({ token, uid, newPassword1, newPassword2 }: { token: string, uid: string, newPassword1: string, newPassword2: string }) => {
	const response = await createHttpRequest(
		RESET_PASSWORD_CONFIRM_ENDPOINT,
		{
			method: 'POST',
			params: { token, uid, newPassword1, newPassword2 }
		})

	return response.data
})

export const initialState: ResetPasswordState = {
	status: RequestStatus.IDLE,
	error: null
}

const resetPasswordSlice = createSlice({
	name: 'resetPassword',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(resetPassword.pending, (state) => {
				state.status = RequestStatus.PENDING
			})
			.addCase(resetPassword.fulfilled, (state) => {
				state.status = RequestStatus.FULFILLED
			})
			.addCase(resetPassword.rejected, (state, action) => {
				state.status = RequestStatus.REJECTED
				state.error = action.error.message
			})
			.addCase(resetPasswordConfirm.pending, (state) => {
				state.status = RequestStatus.PENDING
			})
			.addCase(resetPasswordConfirm.fulfilled, (state) => {
				state.status = RequestStatus.FULFILLED
			})
			.addCase(resetPasswordConfirm.rejected, (state, action) => {
				state.status = RequestStatus.REJECTED
				state.error = action.error.message
			})
			.addDefaultCase(() => {})
	}
})

const { reducer } = resetPasswordSlice

export default reducer
