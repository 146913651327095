import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'

import i18nReducer from './i18n/i18n.slice'
import loginReducer from './login/login.slice'
import profileReducer from './profile/profile.slice'
import registerReducer from './register/register.slice'
import resetPasswordReducer from './resetPassword/resetPassword.slice'
import eventReducer from './event/event.slice'

const createRootReducer = (history: History) => combineReducers({
	router: connectRouter(history),
	i18n: i18nReducer || (() => null),
	login: loginReducer || (() => null),
	profile: profileReducer || (() => null),
	register: registerReducer || (() => null),
	resetPassword: resetPasswordReducer || (() => null),
	event: eventReducer || (() => null)
})

export default createRootReducer
