import { get, set, remove } from '../utils/IonicStorage'
/**
 IonicStorage is a wrapper around LocalStorage, SessionStorage and IndexedDB that makes it easy to store key/value pairs and JSON objects.
 https://ionicframework.com/docs/building/storage
 */
const ads = {
	setIds (id:{siteId:string, pageId:string}) {
		set('ids', id)
	},
	async getIds () {
		return get('ids')
	},
	remove () {
		remove('ids')
	},
	async setConsent (consent: 'yes' | 'no' | 'unknown') {
		 set('consent', consent)
	},
	async getConsent () {
		return await get('consent')
	},
	async removeConsent () {
		await remove('consent')
	}
}

export default ads
