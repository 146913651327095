import { createAsyncThunk, createSlice, AsyncThunk } from '@reduxjs/toolkit'

import { RequestStatus } from '../../../config/constants'
import { FETCH_PROFILE_ENDPOINT } from '../../../config/endpoints'
import { createHttpRequest } from '../../../utils/services'
import { Profile, ProfileState } from './profile.type'

export const fetchProfile: AsyncThunk<Profile, string, {}> = createAsyncThunk('profile/fetchProfile', async (accessToken: string) => {
	const response = await createHttpRequest(
		FETCH_PROFILE_ENDPOINT,
		{
			method: 'GET'
		},
		accessToken
	)

	return response.data
})

export const initialState: ProfileState = {
	profile: {
		firstName: '',
		lastName: '',
		email: '',
		password: ''
	},
	status: RequestStatus.IDLE,
	error: null
}

const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchProfile.pending, (state) => {
				state.status = RequestStatus.PENDING
			})
			.addCase(fetchProfile.fulfilled, (state, action) => {
				state.status = RequestStatus.FULFILLED
				state.profile = action.payload
			})
			.addCase(fetchProfile.rejected, (state, action) => {
				state.status = RequestStatus.REJECTED
				state.error = action.error.message
			})
			.addDefaultCase(() => {})
	}
})

const { reducer } = profileSlice

export default reducer
