import store from '../store'
import { get } from '../utils/IonicStorage'
import userStorage from '../storage/user.storage'
import appConfig from '../storage/config.storage'
/**
 * Http request creator with fetch api and async/await syntax, it also handle the token and the language
 * @param url
 * @param options
 * @param token
 * @returns
 */
export const createHttpRequest = async (url: string, options: { params?: object, method: string }, token: string = null) => {
	const { params, method } = options
	const userToken = await userStorage.getToken()

	const fetchOptions = {
		method,
		body: params && JSON.stringify(params),
		headers: new Headers({
			'Content-Type': 'application/json',
			// 'Accept-Language': store.getState().i18n.language
			'Accept-Language': await userStorage.getLang()
		})
	}

	if (token) {
		fetchOptions.headers.append('Authorization', `Bearer ${token}`)
	}

	if (!token && await userStorage.getToken()) {
		fetchOptions.headers.append('Authorization', `Bearer ${await userStorage.getToken()}`)
	}

	if (method === 'GET' && params) {
		url += '?' + objectToQueryString(params)
	}

	// if ((method === 'POST' || method === 'PUT' || method === 'PATCH') && !params) {
	if ((method === 'PUT' || method === 'PATCH') && !params) {
		throw new Error('You must provide HTTP request payload')
	}

	try {
		const slug = await get('slug')
		const base = slug || 'origin'
		let response:any = null
		if (process.env.ROOT_ENDPOINT.includes('localhost')) {
			response = await fetch(`${process.env.ROOT_ENDPOINT}${url}`, fetchOptions)
		} else {
			response = await fetch(`https://${base}-${process.env.BUILD_ENV}.${process.env.ROOT_ENDPOINT}${url}`, fetchOptions)
		}
		const result = await response.json()

		// Dirty hack to fix empty 204 response
		if (response.status === 204) {
			return Promise.resolve({})
		}

		if (!response.ok) {
			return Promise.resolve(result)
		}

		return result
	} catch (err) {
		return Promise.resolve(err)
	}
}

export const objectToQueryString = (obj: object) => {
	return Object.keys(obj).map(key => key + '=' + obj[key]).join('&')
}
